import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  PieChart, Pie, Cell, ResponsiveContainer,
  LineChart, Line, Area, AreaChart
} from 'recharts';

interface StudentData {
  Nama: string;
  Sektor: string;
  Tipe: string;
  Program: string;
  Bulan: string;
  Tahun: string;
  'Week 1': string;
  'Week 2': string;
  'Week 3': string;
  'Week 4': string;
  'Week 5': string;
  V: number;
  T: number;
  S: number;
  I: number;
  A: number;
  G: number;
  Hadir: number;
  'Tidak Hadir': number;
  '% Kehadiran': number;
  Skor: number;
  Musyrif: string;
  Kitab: string;
  Nilai: number;
  Catatan: string;
  Kursus: string;
  'Bulan Tahun': string;
}

interface FilterOptions {
  bulanTahun: string[];
  sektor: string[];
  tipe: string[];
  musyrif: string[];
  nama: string[];
  program: string[];
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const ReportDashboard: React.FC = () => {
  const [data, setData] = useState<StudentData[]>([]);
  const [filteredData, setFilteredData] = useState<StudentData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    bulanTahun: [],
    sektor: [],
    tipe: [],
    musyrif: [],
    nama: [],
    program: []
  });
  const [selectedFilters, setSelectedFilters] = useState({
    bulanTahun: '',
    sektor: '',
    tipe: '',
    musyrif: '',
    nama: '',
    program: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://script.google.com/macros/s/AKfycbyXtj-xZoV4I_UcC_YzJBljXWRSy2BSeleU1mD6QLhgwOMa0_sEzGzbHwsszkP4lTe4/exec'
        );
        const rawData = response.data.data;
        setData(rawData);
        
        // Extract unique values for filters
        const options: FilterOptions = {
          bulanTahun: [...new Set(rawData.map((item: StudentData) => item['Bulan Tahun']))],
          sektor: [...new Set(rawData.map((item: StudentData) => item.Sektor))],
          tipe: [...new Set(rawData.map((item: StudentData) => item.Tipe))],
          musyrif: [...new Set(rawData.map((item: StudentData) => item.Musyrif))],
          nama: [...new Set(rawData.map((item: StudentData) => item.Nama))],
          program: [...new Set(rawData.map((item: StudentData) => item.Program))]
        };
        setFilterOptions(options);
        setFilteredData(rawData);
      } catch (err) {
        setError('Error fetching data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filtered = [...data];
    
    if (selectedFilters.bulanTahun) {
      filtered = filtered.filter(item => item['Bulan Tahun'] === selectedFilters.bulanTahun);
    }
    if (selectedFilters.sektor) {
      filtered = filtered.filter(item => item.Sektor === selectedFilters.sektor);
    }
    if (selectedFilters.tipe) {
      filtered = filtered.filter(item => item.Tipe === selectedFilters.tipe);
    }
    if (selectedFilters.musyrif) {
      filtered = filtered.filter(item => item.Musyrif === selectedFilters.musyrif);
    }
    if (selectedFilters.nama) {
      filtered = filtered.filter(item => item.Nama === selectedFilters.nama);
    }
    if (selectedFilters.program) {
      filtered = filtered.filter(item => item.Program === selectedFilters.program);
    }

    setFilteredData(filtered);
  }, [selectedFilters, data]);

  const handleFilterChange = (filterType: string, value: string) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
  
  if (error) return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    </div>
  );

  // Kalkulasi data untuk charts
  const attendanceByMusyrif = Object.values(
    filteredData.reduce((acc, curr) => {
      if (!acc[curr.Musyrif]) {
        acc[curr.Musyrif] = {
          name: curr.Musyrif,
          hadir: 0,
          tidakHadir: 0,
          total: 0
        };
      }
      acc[curr.Musyrif].hadir += curr.Hadir;
      acc[curr.Musyrif].tidakHadir += curr['Tidak Hadir'];
      acc[curr.Musyrif].total += 1;
      return acc;
    }, {} as Record<string, any>)
  );

  const nilaiDistribution = Object.values(
    filteredData.reduce((acc, curr) => {
      const range = Math.floor(curr.Nilai / 10) * 10;
      const key = `${range}-${range + 9}`;
      if (!acc[key]) {
        acc[key] = {
          name: key,
          value: 0
        };
      }
      acc[key].value += 1;
      return acc;
    }, {} as Record<string, any>)
  );

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Dashboard Laporan</h1>

        {/* Filters */}
        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Filters</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.entries(filterOptions).map(([key, options]) => (
              <div key={key} className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 capitalize">
                  {key.replace(/([A-Z])/g, ' $1').trim()}
                </label>
                <select
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={selectedFilters[key as keyof typeof selectedFilters]}
                  onChange={(e) => handleFilterChange(key, e.target.value)}
                >
                  <option value="">All</option>
                  {options.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>

        {/* Charts Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Kehadiran per Musyrif */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Kehadiran per Musyrif</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={attendanceByMusyrif}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="hadir" fill="#4CAF50" name="Hadir" />
                <Bar dataKey="tidakHadir" fill="#FF5252" name="Tidak Hadir" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Distribusi Nilai */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Distribusi Nilai</h2>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={nilaiDistribution}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, value }) => `${name}: ${value}`}
                >
                  {nilaiDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>

          {/* Tren Kehadiran */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Tren Kehadiran</h2>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={attendanceByMusyrif}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="hadir" stackId="1" stroke="#8884d8" fill="#8884d8" />
                <Area type="monotone" dataKey="tidakHadir" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
              </AreaChart>
            </ResponsiveContainer>
          </div>

          {/* Data Table */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Data Terkini</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nama</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Musyrif</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">% Kehadiran</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nilai</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredData.slice(0, 5).map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.Nama}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.Musyrif}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex items-center">
                          <div className="w-full bg-gray-200 rounded-full h-2.5">
                            <div
                              className="bg-blue-600 h-2.5 rounded-full"
                              style={{ width: `${item['% Kehadiran']}%` }}
                            ></div>
                          </div>
                          <span className="ml-2">{item['% Kehadiran']}%</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <span className={`px-2 py-1 rounded-full ${
                          item.Nilai >= 80 ? 'bg-green-100 text-green-800' :
                          item.Nilai >= 60 ? 'bg-yellow-100 text-yellow-800' :
                          'bg-red-100 text-red-800'
                        }`}>
                          {item.Nilai}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDashboard;