import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import AttendancePage from './components/attendance/AttendancePage';
import ContactsPage from './components/contacts/ContactsPage';
import AssessmentPage from './components/assessment/AssessmentPage';
import GuidePage from './components/guide/GuidePage';
import TeacherReportPage from './components/report/TeacherReportPage';
import WhatsAppGeneratorPage from './components/whatsapp/WhatsAppGeneratorPage';
import { Menu } from 'lucide-react';
import MutabaahPage from './components/mutabaah/MutabaahPage';
import KajianPage from './components/kajian/KajianPage';
import ListDataForm from './components/listdata/ContactForm';
import MappingForm from './components/mapping/ContactForm';
import ReportDashboard from './components/report/ReportDashboard';


function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <button
          onClick={() => setIsSidebarOpen(true)}
          className="fixed top-4 left-4 p-2 rounded-lg bg-white shadow-md hover:bg-gray-50 lg:hidden z-50"
        >
          <Menu className="w-6 h-6 text-gray-600" />
        </button>

        <div className="flex">
          <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
          <main className="flex-1">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/attendance" element={<AttendancePage />} />
              <Route path="/contacts" element={<ContactsPage />} />
              <Route path="/assessment" element={<AssessmentPage />} />
              <Route path="/guide" element={<GuidePage />} />
              <Route path="/mutabaah" element={<MutabaahPage />} />

              <Route path="/report" element={<TeacherReportPage />} />
              <Route path="/report/dashboard" element={< ReportDashboard/>} />
              <Route path="/whatsapp" element={<WhatsAppGeneratorPage />} />
              <Route path="/kajian" element={<KajianPage />} />
              <Route path="/listdata" element={
                <div className="flex flex-col min-h-screen bg-gray-100">
                  <div className="flex-1 py-8">
                    <div className="text-center mb-8">
                      <h1 className="text-3xl font-bold text-gray-800 mb-4">Pemetaan Untuk Upgrading Musyrif</h1>
                      <p className="text-gray-600">Silakan isi formulir di bawah ini</p>
                    </div>
                    <div className="container mx-auto px-4">
                      <div className="bg-white rounded-lg shadow p-6">
                        <ListDataForm />
                      </div>
                    </div>
                  </div>
                </div>
              } />
              <Route path="/mapping" element={
                <div className="flex flex-col min-h-screen bg-gray-100">
                  <div className="flex-1 py-12">
                    
                    <div className="container mx-auto px-8">
                      <div className="bg-white rounded-lg shadow p-6">
                        <MappingForm />
                      </div>
                    </div>
                  </div>
                </div>
              } />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
