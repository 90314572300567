import React, { useState, useEffect } from 'react';
import { Users } from 'lucide-react';
import FormField from '../common/FormField';

interface TeacherRegistrationProps {
  onComplete: (data: { sektor: string; musyrif: string; kategori: string; pelajar: string[] }) => void;
}

const sektorData = {
  'Cangkuang': {
    musyrif: ['Anggara', 'Hari', 'Usman', 'Imam', 'Dani', 'Budi', 'Arief', 'Muchtar','Aef','Utis'],
    pelajar: {
      'Anggara': ['Hari', 'Utis', 'Usman'],
      'Hari': ['Husen'],
      'Usman': ['Indra'],
      'Dani': ['Anggara'],
      'Imam': ['Aep'],
      'Arief': ['Muchtar'],
      'Budi': ['Erlan'],
      'Muchtar': ['Wiwi', 'Mirza', 'Dani Hendar'],
      'Aef': ['Arief']
    },
    hu: {
      'Anggara': [],
      'Utis': ['Otong'],
      'Usman': [],
      'Imam': [],
      'Indra Gumilar': [],
      'Hamidan Baleendah': [],
      'Toni': [],
      'Aef': ['Mulyadi']
    },
    cp: {
      'Anggara': [],
      'Utis': [],
      'Usman': [],
      'Imam': [],
      'Indra Gumilar': [],
      'Hamidan Baleendah': [],
      'Toni': []
    }
  },
  'Pangalengan': {
    musyrif: ['Indra Gumilar P', 'Mama Abdul Kelompok 1', 'Mama Abdul Kelompok 2', 'Indra Gumilar K', 'Mama Abdul Kelompok 3','Riki', 'Ust Dede Bojongsoang', 'Anggara'],
    pelajar: {
      'Indra Gumilar K': ['Mama Abdul'],
      'Mama Abdul Kelompok 1': ['Rikrik', 'Abi', 'Pak Enjang', 'Fajar', 'Syarif'],
      'Mama Abdul Kelompok 2': ['Mulyana Cidaun'],
      'Indra Gumilar P': ['Pak Eman', 'Pak Usan RT', 'Pak Yana'],
      'Mama Abdul Kelompok 3': ['Danis', 'Harry', 'Opik', 'Ujang'],
      'Ust Dede Bojongsoang': ['Indra Gumilar'],
      'Anggara': ['Didi', 'Nasim', 'Tursiwa'],
      'Riki': ['Agnan']
    },
    hu: {
      'Dani': [],
      'Mama Abdul Kelompok 1': [],
      'Mama Abdul Kelompok 2': [],
      'Mama Abdul Kelompok 3': [],
      'Indra Gumilar': [],
      'Ust Dede Bojongsoang': [],
      'Anggara': ['Didi', 'Nasim', 'Tursiwa']
    },
    cp: {
      'Dani': [],
      'Mama Abdul Kelompok 1': [],
      'Mama Abdul Kelompok 2': [],
      'Mama Abdul Kelompok 3': [],
      'Indra Gumilar': [],
      'Ust Dede Bojongsoang': []
    }
  },
  'Banjaran': {
    musyrif: ['Agnan', 'Aziz', 'Riki', 'Dani', 'Budi', 'Nandar','Muchtar K', 'Muchtar P', 'Imam', 'Asep', 'Engkos', 'Ito', 'Mulyana'],
    pelajar: {
      'Agnan': ['Iman', 'Furqon'],
      'Aziz': ['Sofian', 'Ridwan', 'Firmansyah'],
      'Riki': ['Toni', 'Nandar'],
      'Dani': ['Asep', 'Rahmat', 'Deni P'],
      'Budi': ['Arba', 'Fardan'],
      'Nandar': [],
      'Muchtar K': [ 'Riki'],
      'Imam': [ 'Mulyana'],
      'Asep': ['Toha', 'Amin', 'Ridwan Muhammad'],
      'Muchtar P': ['Sadarudin'],
      'Engkos': ['Ito', 'Deni N'],
      'Ito': ['Hanif'],
      'Mulyana': ['Pak Achmad', 'Rizki', 'Agung']
    },
    hu: {
      'Agnan': [],
      'Aziz': [],
      'Riki': [],
      'Imam': [],
      'Toni': [],
      'Nandar': [],
      'Muchtar': [],
      'Indra Gumilar': [],
      'Asep Kelompok 1': [],
      'Asep Kelompok 2': [],
      'Engkos': [],
      'Ito': [],
      'Mulyana': []
    },
    cp: {
      'Agnan': [],
      'Aziz': [],
      'Riki': [],
      'Imam': [],
      'Toni': [],
      'Nandar': [],
      'Muchtar': [],
      'Indra Gumilar': [],
      'Asep Kelompok 1': [],
      'Asep Kelompok 2': [],
      'Engkos': [],
      'Ito': ['Luky'],
      'Mulyana': []
    }
  },
  'Arjasari': {
    musyrif: [ 'Aziz', 'Dani', 'Budi', 'Nandar','Muchtar', 'Imam', 'Engkos', 'Ito', 'Asep CP', 'Hari', 'Jajang'],
    pelajar: {
      'Aziz': [ 'Hidayat', 'Tio'],
      'Dani': ['Engkos'],
      'Budi': ['Yusuf'],
      'Nandar': ['Marno'],
      'Muchtar': ['Nurdin', 'Aziz', 'Budi'],
      'Imam': [ 'Dani'],
      'Engkos': ['Iqbal'],
      'Ito': ['Refky'],
      'Hari': ['Ugi'],
      'Jajang': ['Imam']
    },
    hu: {
      'Aziz': [],
      'Imam': ['Dadan', 'Tahyu'],
      'Engkos': []
    },
    cp: {
      'Aziz': [],
      'Imam': [],
      'Asep CP': ['Ayi', 'Jajang'],
      'Asep Kelompok 2': [],
      'Engkos': []
    }
  }
};

const TeacherRegistration: React.FC<TeacherRegistrationProps> = ({ onComplete }) => {
  const [sektor, setSektor] = useState('');
  const [musyrif, setMusyrif] = useState('');
  const [kategori, setKategori] = useState('pelajar');
  const [selectedPelajar, setSelectedPelajar] = useState<string[]>([]);
  const [musyrifList, setMusyrifList] = useState<string[]>([]);
  const [pelajarList, setPelajarList] = useState<string[]>([]);

  useEffect(() => {
    if (sektor && musyrif && kategori) {
      const data = sektorData[sektor as keyof typeof sektorData][kategori as 'pelajar' | 'hu' | 'cp'][musyrif] || [];
      setPelajarList(data);
      setSelectedPelajar([]);
    }
  }, [sektor, musyrif, kategori]);

  useEffect(() => {
    if (sektor) {
      setMusyrifList(sektorData[sektor as keyof typeof sektorData].musyrif);
      setMusyrif('');
      setSelectedPelajar([]);
    }
  }, [sektor]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (sektor && musyrif && selectedPelajar.length > 0) {
      onComplete({
        sektor,
        musyrif,
        kategori,
        pelajar: selectedPelajar
      });
    } else {
      alert('Mohon lengkapi semua data dan pilih minimal satu peserta');
    }
  };

  const kategoriOptions = [
    { value: 'pelajar', label: 'Pelajar' },
    { value: 'hu', label: 'Halqah Umum' },
    { value: 'cp', label: 'Calon Pelajar' }
  ];

  const getLabelByKategori = () => {
    switch (kategori) {
      case 'hu':
        return 'Halqah Umum';
      case 'cp':
        return 'Calon Pelajar';
      default:
        return 'Pelajar';
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="text-center mb-8">
        <div className="inline-flex items-center justify-center w-16 h-16 bg-green-600 rounded-full mb-4">
          <Users className="w-8 h-8 text-white" />
        </div>
        <h2 className="text-2xl font-bold text-gray-800">Form Registrasi Guru</h2>
        <p className="text-gray-600 mt-2">Silakan lengkapi data untuk melanjutkan ke form laporan</p>
      </div>

      <FormField
        label="Sektor"
        name="sektor"
        type="select"
        value={sektor}
        onChange={(e) => {
          setSektor(e.target.value);
          setMusyrif('');
          setKategori('pelajar');
          setSelectedPelajar([]);
        }}
        options={Object.keys(sektorData).map(s => ({ value: s, label: s }))}
      />

      {sektor && (
        <FormField
          label="Musyrif"
          name="musyrif"
          type="select"
          value={musyrif}
          onChange={(e) => {
            setMusyrif(e.target.value);
            setSelectedPelajar([]);
          }}
          options={sektorData[sektor as keyof typeof sektorData].musyrif.map(m => ({ value: m, label: m }))}
        />
      )}

      {musyrif && (
        <FormField
          label="Kategori"
          name="kategori"
          type="select"
          value={kategori}
          onChange={(e) => {
            setKategori(e.target.value);
            setSelectedPelajar([]);
          }}
          options={kategoriOptions}
        />
      )}

      {musyrif && pelajarList.length > 0 && (
        <FormField
          label={getLabelByKategori()}
          name="pelajar"
          type="multiselect"
          value={selectedPelajar}
          onChange={(e) => {
            const options = e.target.options;
            const value: string[] = [];
            for (let i = 0, l = options.length; i < l; i++) {
              if (options[i].selected) {
                value.push(options[i].value);
              }
            }
            setSelectedPelajar(value);
          }}
          options={pelajarList.map(p => ({ value: p, label: p }))}
        />
      )}

      <div className="flex justify-end">
        <button
          type="submit"
          className="bg-green-600 text-white px-6 py-2 rounded hover:bg-green-700 transition-colors"
        >
          Lanjutkan
        </button>
      </div>
    </form>
  );
};

export default TeacherRegistration;
